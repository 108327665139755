
/**
 * @name: details
 * @author: lili
 * @date: 2023-06-01 11:21
 * @description：销售管理-网红视频管理-添加，修改  详情信息
 * @update: 2023-06-01 11:21
 */
import {Component, Prop, Vue} from "vue-property-decorator";
import config from "@/config";
import {ICourse} from "@/apis/coursesManage/courses/types";
import Tinymce from "@/components/Tinymce/index.vue";

@Component({
  components: {Tinymce}
})
export default class ProductBasic extends Vue {
  config = config;
  // 新增修改表单
  @Prop(Object) modelForm!: ICourse;
  // 新增修改表单rules
  @Prop(Object) rules!: any;
  /**
   * 完成
   *
   */
  handleFinish() {
    this.$emit('Finish', this.modelForm)

  }
  /**
   * 下一步，上一步
   * @param number
   *
   */
  handleNext(tab: string) {
    this.$emit('Next', this.modelForm,tab)
  }

  created() {
  }
}
